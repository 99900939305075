const converterFiles = document.querySelector(".converter__files");
const converterButton = document.querySelector(".converter__button");
const converterUpload = document.querySelector(".converter__upload");
const form = document.querySelector(".form");
const converterSaved = document.querySelector(".converter__saved");
const converterSlidersSize = document.querySelector(".converter__sliders-size");
const converterSlidersQuality = document.querySelector(
  ".converter__sliders-quality"
);

converterSlidersSize.addEventListener("input", (e) => {
  const closestValueDiv = e.target
    .closest(".converter__sliders-single")
    .querySelector(".converter__sliders-single-value");

  closestValueDiv.innerHTML = e.target.value;

  const allDimensions = document.querySelectorAll(
    ".converter__files-single-dimension"
  );

  allDimensions.forEach((event) => {
    const originalDimensions = event.dataset.originaldimensions;
    const percentageSorted = e.target.value / 100;

    const mathsinit = originalDimensions * percentageSorted;

    event.textContent = mathsinit.toFixed();

    // console.log(originalDimensions);
  });
});

converterSlidersQuality.addEventListener("input", (e) => {
  const closestValueDiv = e.target
    .closest(".converter__sliders-single")
    .querySelector(".converter__sliders-single-value");

  closestValueDiv.innerHTML = e.target.value;
});

let allsize = 0;

converterUpload.addEventListener("change", () => {
  const files = converterUpload.files;

  console.log(files.length);
  console.log(typeof files);
  console.log(files);
  converterFiles.innerHTML = "";

  allsize = 0;

  if (files.length > 0 && files.length < 11) {
    converterButton.removeAttribute("disabled");

    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        var value = files[key];
        if (value instanceof File) {
          const fileName = value.name;
          const fileSize = Number(value.size);
          allsize += fileSize;

          let fileType;

          if (fileSize < 1000000) {
            const num = Number(fileSize / 1000).toFixed(0);
            fileType = `${num} KB`;
          } else {
            const num = Number(fileSize / 1000000).toFixed(2);
            fileType = `${num} MB`;
          }

          // Create a FileReader to read the image data
          const reader = new FileReader();

          reader.onload = (event) => {
            // Create an Image object to get dimensions
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
              // Get image dimensions
              const width = img.width;
              const height = img.height;

              const HTML = `<div class="converter__files-single" data-index=${key}>
                              <div>${fileName}</div>
                              <div>${fileType}</div>
                              <div>Dimensions: <span class="converter__files-single-dimension" data-originaldimensions="${width}">${width}</span> x <span class="converter__files-single-dimension" data-originaldimensions="${height}">${height}</span></div>
                            </div>`;

              converterFiles.insertAdjacentHTML("afterbegin", HTML);
            };
          };

          // Read the image data as a data URL
          reader.readAsDataURL(value);
        }
      }
    }

    console.log(allsize);
  }
});

function uploadFiles() {
  // Create a FormData object to store the form data

  const sizeValue = converterSlidersSize.value;
  const qualityValue = converterSlidersQuality.value;
  var formData = new FormData(form);
  formData.append("size", sizeValue);
  formData.append("quality", qualityValue);
  converterButton.innerHTML = "Converting...";
  const startTimestamp = Date.now();

  // Send the form data to the server using fetch
  fetch(
    "https://digital-energy-image-converter-71b5223af6da.herokuapp.com/upload",
    {
      method: "POST",
      body: formData,
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Assuming the server returns a ZIP file, you can trigger the download

      return response.blob();
    })
    .then((blob) => {
      const fileSizeInBytes = blob.size;
      const endTimestamp = Date.now();

      console.log(`File size before: ${allsize} kb`);
      console.log(`File size after: ${fileSizeInBytes} kb`);
      console.log(
        `percentage = ${Number(100 - (fileSizeInBytes / allsize) * 100).toFixed(
          0
        )}`
      );

      const timeTaken = Number(endTimestamp - startTimestamp) / 1000;

      converterSaved.innerHTML = `<p>You Saved: ${Number(
        100 - (fileSizeInBytes / allsize) * 100
      ).toFixed(0)}% in ${timeTaken} seconds</p>`;

      const url = URL.createObjectURL(blob);
      const fileName = "compressed-images-digital-energy.zip"; // Specify the desired file name
      downloadFile(url, fileName);

      // Log a message indicating that the ZIP file has been received
      console.log("ZIP file received successfully!");
      converterButton.innerHTML = "Upload";
    })
    .catch((error) => {
      // Handle errors
      console.error("Error:", error);
    });
}

converterButton.addEventListener("click", (e) => {
  e.preventDefault();
  uploadFiles();
});

function downloadFile(url, fileName) {
  // Create a link element
  const link = document.createElement("a");

  // Set the href and download attributes
  link.href = url;
  link.download = fileName;

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
}
